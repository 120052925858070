import * as React from "react";
import { Layout, Link, Button } from "components";

const NotFoundPage = () => {
  return (
    <Layout>
      <section className="layout--small section--small" style={{ textAlign: "center" }}>
        <div>
          <h1>SORRY, WE CAN'T FIND THE PAGE YOU ARE LOOKING FOR</h1>
          <p>
            It seems we can't find what you're looking for. The page you have requested cannot be displayed. This could
            mean that the property is sold or currently not available for sale online.
          </p>
          <Link to="/">
            <Button>Back to homepage</Button>
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
